/* eslint-disable no-unused-vars */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { ErrorFormat, ErrorToast } from 'everchain-uilibrary'
import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { getLocalStorageUser } from 'src/utils/common'

export const BACKEND_URL = process.env.REACT_APP_API
export const MARKETPLACE_BACKEND_URL = `${process.env.REACT_APP_MARKETPLACE_API_REST}/api`
export const CONTROLPANEL_URL = `${process.env.REACT_APP_CONTROLPANEL_REST}/api`

enum StatusCode {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
}

// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
// eslint-disable-next-line consistent-return
const injectToken = (config: AxiosRequestConfig) => {
  try {
    return config
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export class Http implements IHttpClient {
  private instance: AxiosInstance | null = null

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp()
  }

  initHttp() {
    const user = getLocalStorageUser()
    const userToken = user ? `bearer ${JSON.parse(user).access_token}` : ''
    const http = axios.create({
      baseURL: process.env.REACT_APP_API,
      headers: {
        Authorization: userToken,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest',
      },
      //   withCredentials: true,
    })

    http.interceptors.request.use(injectToken, (error) => Promise.reject(error))

    http.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error
        return this.handleError(response)
      }
    )

    this.instance = http
    return http
  }

  request<T = any, R = AxiosResponse<T>>(config: any): Promise<R> {
    return this.http.request(config)
  }

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.get<T, R>(url, config)
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.post<T, R>(url, data, config)
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config)
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.delete<T, R>(url, config)
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  // eslint-disable-next-line class-methods-use-this
  private handleError(result: any) {
    switch (result?.status) {
      case StatusCode.BAD_REQUEST: {
        ErrorToast(ErrorFormat(result.data))
        break
      }
      case StatusCode.UNAUTHORIZED: {
        ErrorToast('Unauthorized. Please log in.')
        break
      }
      case StatusCode.FORBIDDEN: {
        ErrorToast('Access Denied.')
        break
      }
      case StatusCode.TOO_MANY_REQUESTS: {
        ErrorToast('Too Many Requests. Try again later.')
        break
      }
      case StatusCode.INTERNAL_SERVER_ERROR: {
        if (process.env.REACT_APP_SHOW_CUSTOM_ERROR) {
          ErrorToast('Server Error: ' + result?.data?.title)
        } else {
          ErrorToast(
            'Server Error. If the problem persists, please contact the EverChain team.'
          )
        }
        break
      }
    }

    return Promise.reject(result)
  }
}

export const http = new Http()
