import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { PortfolioGetInfoTemplates } from './urls'
import { IPortfolioGetOperations } from 'src/domain/features/get/portfolio/portfolio'
import { PortfolioTemplateInfo } from 'src/domain/models/portfolio/portfolio'

export class PortfolioData implements IPortfolioGetOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async getInfoTemplatesData(): Promise<PortfolioTemplateInfo[]> {
    const response = await this.httpClient.get(`${PortfolioGetInfoTemplates}`)
    return response?.data
  }
}
