import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { MediaUploadPostSaveMediaFile } from './urls'
import { IMediaUploadPostOperations } from 'src/domain/features/post/mediaUpload/mediaUpload'

export class MediaUploadPostData implements IMediaUploadPostOperations {
  constructor(private readonly httpClient: IHttpClient) {}
  async saveMediaFile(): Promise<string> {
    const response = await this.httpClient.post(MediaUploadPostSaveMediaFile)
    return response?.data
  }
}
