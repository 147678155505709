import React, { useState } from 'react'
import {
  Button,
  Box,
  SuccessToast,
  UploadDragDrop,
  Step,
} from 'everchain-uilibrary'
import { IMediaUploadGetOperations } from 'src/domain/features/get/mediaUpload/mediaUpload'
import { IMediaUploadPostOperations } from 'src/domain/features/post/mediaUpload/mediaUpload'
import { downloadBase64File } from 'src/utils/file/fileDownload'

interface PortfolioMediaFileFormProps {
  mediaUploadGetOperations: IMediaUploadGetOperations
  mediaUploadPostOperations: IMediaUploadPostOperations
}
const MediaFileForm: React.FC<PortfolioMediaFileFormProps> = ({
  mediaUploadGetOperations,
  mediaUploadPostOperations,
}) => {
  const [files, setFiles] = useState<any[]>([])
  const [loadingData, setLoadingData] = useState<boolean>(false)

  const handleDownloadAllMedia = async () => {
    setLoadingData(true)
    const template = await mediaUploadGetOperations?.getAllAccountMedia()
    downloadBase64File(template)
    SuccessToast('All Account Media are downloaded.')
    setLoadingData(false)
  }

  const handleDownloadMediaTemplate = async () => {
    setLoadingData(true)
    const template =
      await mediaUploadGetOperations?.getDownloadMediaTemplateFile()
    downloadBase64File(template)
    SuccessToast('Download Template File downloaded.')
    setLoadingData(false)
  }

  const handleUploadMediaTemplate = async () => {
    setLoadingData(true)
    const template = await mediaUploadGetOperations?.getManifestTemplateFile()
    downloadBase64File(template)
    SuccessToast('All Account Media are downloaded.')
    setLoadingData(false)
  }

  return (
    <>
      <Box padding={2}>
        <Button
          useRipple
          width={380}
          height={40}
          isLoading={loadingData}
          onClick={handleDownloadAllMedia}
        >
          Download All Account Media
        </Button>
      </Box>
      <Box padding={2}>
        <Button
          useRipple
          width={380}
          height={40}
          isLoading={loadingData}
          onClick={handleDownloadMediaTemplate}
        >
          Download Media Download Template
        </Button>
      </Box>
      <Box padding={2}>
        <Step
          title="Upload Media Download Template"
          stepNumber=""
          completed={files[0] !== undefined}
        >
          <UploadDragDrop
            files={files}
            setFiles={setFiles}
            hideUploadButton={true}
          />
        </Step>
      </Box>
      <Box padding={2} display="flex" justifyContent="flex-end">
        <Button
          useRipple
          width={140}
          height={40}
          disabled={files.length === 0}
          isLoading={loadingData}
          onClick={handleUploadMediaTemplate}
        >
          Upload File
        </Button>
      </Box>
    </>
  )
}

export default MediaFileForm
