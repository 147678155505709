import { DataTableState } from 'everchain-uilibrary'
import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { IStrategyGetOperations } from 'src/domain/features/get/strategy/strategy'
import { DataResponse } from 'src/domain/models/common'
import { Strategy, StrategyStage } from 'src/domain/models/strategy'
import { getCurrentPage } from 'src/utils/grid'
import {
  StrategyGetStrategies,
  StrategyGetStrategyByCreditorId,
  StrategyGetStrategyById,
  StrategyGetStrategyStage,
  StrategyStageTotalDistribution,
} from './urls'

export class StrategyGetData implements IStrategyGetOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async getTotalDistribution(
    strategyId: number,
    strategyStageId: number
  ): Promise<number> {
    const response = await this.httpClient.get(
      `${StrategyStageTotalDistribution}?strategyId=${strategyId}&strategyStageId=${strategyStageId}`
    )
    return response.data
  }
  async getStrategyById(id: number): Promise<Strategy> {
    const response = await this.httpClient.get(
      `${StrategyGetStrategyById}?id=${id}`
    )
    return response.data
  }

  async getStrategies(
    pagination: DataTableState,
    creditorId?: string,
    onlyEnabled?: boolean
  ): Promise<DataResponse<Strategy>> {
    const creditorFilter = creditorId ? `&creditorId= ${creditorId} ` : ''
    const response = await this.httpClient.get(
      `${StrategyGetStrategies}?kendoPagination=${JSON.stringify(
        pagination
      )}&pageNumber=${getCurrentPage(pagination)}&pageSize=${
        pagination.take
      }&onlyEnabled=${onlyEnabled}${creditorFilter}`
    )
    return response.data
  }

  async getStrategyStageByStrategyId(
    pagination: DataTableState,
    strategyId: number
  ): Promise<DataResponse<StrategyStage>> {
    const response = await this.httpClient.get(
      `${StrategyGetStrategyStage}?pageNumber=${getCurrentPage(
        pagination
      )}&pageSize=${pagination.take}&strategyId=${strategyId}`
    )
    return response.data
  }

  async getStrategyByCreditorId(creditorId: string): Promise<Strategy[]> {
    const response = await this.httpClient.get(
      `${StrategyGetStrategyByCreditorId}?creditorId=${creditorId}`
    )
    return response.data
  }
}
