import React, { createContext, useContext, useMemo } from 'react'
import { Action, IAuthContext, Permissions } from './Interfaces'

export interface UserPermissions {
  isReseller: boolean
  type: 'internal' | 'Buyer' | 'Seller'
}

export interface Business {
  ClientId: number
  Country: string
  Id: number
  Name: string
  Status: string
  Type: string
}

export const OldAuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  userPermissions: {
    type: 'Buyer',
    isReseller: false,
  },
  profileBusiness: [],
  profileClient: [],
  managePermissions: [],
  selectedType: '',
  isVendor: false,
  isCreditor: false,
  dispatch: () => {},
  login: () => {},
  logout: () => {},
  renewToken: () => {},
  isAuth: (): boolean => false,
  getPermissionsByModule: (): Permissions[] => [],
})

interface AuthProviderProps {
  children: React.ReactNode
  isAuthenticated: Boolean
  user?: Oidc.User | any
  userPermissions: UserPermissions
  profileBusiness: Business[]
  profileClient: [] | any
  managePermissions: Permissions[]
  dispatch: React.Dispatch<Action>
  login: () => void
  logout: () => void
  renewToken: () => void
  isAuth: () => boolean
  getPermissionsByModule: (moduleId?: string) => Permissions[]
}

export const useAuth = (): IAuthContext => useContext(OldAuthContext)

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  ...rest
}) => {
  const values = useMemo(
    () => ({
      ...rest,
    }),
    [rest]
  )

  return (
    <OldAuthContext.Provider
      value={{
        ...values,
        selectedType: '',
        isCreditor: false,
        isVendor: false,
      }}
    >
      {children}
    </OldAuthContext.Provider>
  )
}
