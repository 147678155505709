/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { ModalDialog } from 'everchain-uilibrary'
import { makeMediaUploadPostData } from 'src/main/factories/feature/post/MediaUpload'
import { mockMediaUploadGetOperations } from 'src/data/features/get/mediaUpload/mockMediaUpload'
import {
  mockGetAllMediaAccount,
  mockGetDownloadTemplateFile,
  mockGetManifestTemplateFile,
  mockManifestBlobUrls,
} from 'src/domain/models/mediaUpload/mock_mediaUpload'
import DownloadOptions from './DownloadOptions'

interface DownloadModalProps {
  open: boolean
  setOpenUploadModal: any
}

const DownloadModal: React.FC<DownloadModalProps> = ({
  open,
  setOpenUploadModal,
}: DownloadModalProps) => {
  return (
    <>
      <ModalDialog
        isOpen={open}
        hideOkButton={true}
        hideCancelButton={true}
        onClose={() => {
          setOpenUploadModal(false)
        }}
        header="Download Bulk Media"
        style={{ minWidth: '420px', minHeight: '300px' }}
      >
        <DownloadOptions
          mediaUploadGetOperations={mockMediaUploadGetOperations(
            mockGetManifestTemplateFile,
            mockManifestBlobUrls,
            mockGetDownloadTemplateFile,
            mockGetAllMediaAccount
          )}
          mediaUploadPostOperations={makeMediaUploadPostData()}
        ></DownloadOptions>
      </ModalDialog>
    </>
  )
}

export default DownloadModal
