import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import {
  Business,
  BusinessSettings,
  PlacedBusiness,
  PostPlacementType,
} from 'src/domain/models/business'
import { GetAccessToken } from 'src/utils/helper'
import {
  BusinessGetCreditors,
  BusinessGetVendorsByStrategyAndStage,
  BusinessGetVendors,
  BusinessGetVendorsByStrategy,
  BusinessGetCreditorsRelated,
  BusinessGetVendorsRelated,
  BusinessGetPlacedBusiness,
  BusinessGetBusinessSettings,
  BusinessGetBusinessesSettings,
  BusinessGetBusinessWithoutBusinessSettings,
} from './urls'
import { AccountRetrievalType } from 'src/utils/constants'
import { DataTableState } from 'everchain-uilibrary'
import { DataResponse } from 'src/domain/models/common'

export class BusinessData implements IBusinessGetOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async getVendorsDataByStrategyAndStage(
    strategyId: number,
    strategyStageId: number,
    distributionId?: number
  ): Promise<Business[]> {
    const response = await this.httpClient.get(
      `${BusinessGetVendorsByStrategyAndStage}?stageId=${strategyStageId}&strategyId=${strategyId}&distributionId=${distributionId}`
    )
    return response?.data?.vendors
  }
  async getVendorsDataByStrategy(strategyId: number): Promise<Business[]> {
    const response = await this.httpClient.get(
      `${BusinessGetVendorsByStrategy}?strategyId=${strategyId}`
    )
    return response?.data?.vendors
  }
  async getCreditorsData(): Promise<Business[]> {
    const response = await this.httpClient.get(BusinessGetCreditors)
    return response?.data?.creditors
  }

  async getPostPlacementTypesData(): Promise<PostPlacementType[]> {
    return [
      {
        id: '0',
        name: 'Bankruptcy',
      },
    ]
  }

  async getVendorsData(): Promise<Business[]> {
    const response = await this.httpClient.get(BusinessGetVendors)
    return response?.data?.vendors
  }
  async getCreditorsRelatedData(businessId: any): Promise<Business[]> {
    const businessesId = encodeURIComponent(JSON.stringify(businessId))
    const response = await this.httpClient.get(
      `${BusinessGetCreditorsRelated}?businessId=${businessesId}`
    )
    return response?.data?.creditors
  }

  async getVendorsRelatedData(businessId: any): Promise<Business[]> {
    const businessesId = encodeURIComponent(JSON.stringify(businessId))
    const response = await this.httpClient.get(
      `${BusinessGetVendorsRelated}?businessId=${businessesId}`
    )
    return response?.data?.vendors
  }

  getVendorsByOperationStage = (operationStage: string) => {
    return `query {
        getVendorsByOperationStage(operationStage: ${operationStage}) {
        id
        name
        status      
        businessType 
        }
      }
    }`
  }

  async getVendorsByOperationStageData(
    operationStage: string
  ): Promise<Business[]> {
    const response = await this.httpClient.request({
      url: process.env.REACT_APP_CONTROLPANEL,
      method: 'post',
      headers: {
        Authorization: GetAccessToken(),
      },
      data: {
        query: `${this.getVendorsByOperationStage(operationStage)}`,
      },
    })

    return response.data.data.getVendors
  }

  async getPlacedBusiness(
    businessId: string | undefined,
    operationType: AccountRetrievalType
  ): Promise<PlacedBusiness> {
    const businessList = encodeURIComponent(JSON.stringify(businessId))
    const response = await this.httpClient.get(
      `${BusinessGetPlacedBusiness}?businessId=${businessList}&operationType=${operationType}`
    )
    return response?.data
  }

  async getBusinessSettings(
    businessId: string | undefined
  ): Promise<BusinessSettings> {
    const response = await this.httpClient.get(
      `${BusinessGetBusinessSettings}?businessId=${businessId}`
    )
    return response?.data
  }

  async getBusinessesSettings(
    pagination: DataTableState
  ): Promise<DataResponse<BusinessSettings>> {
    const encodedData = encodeURIComponent(JSON.stringify(pagination))
    const response = await this.httpClient.get(
      `${BusinessGetBusinessesSettings}?pagination=${encodedData}`
    )
    return response?.data
  }

  async getBusinessWithoutBusinessSettings(): Promise<Business[]> {
    const response = await this.httpClient.get(
      BusinessGetBusinessWithoutBusinessSettings
    )
    return response?.data
  }
}
