import { IPostPlacementGetOperations } from 'src/domain/features/get/postPlacement/postPlacement'
import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { DataTableState } from 'everchain-uilibrary'
import { Account } from 'src/domain/models/accounts'
import { DataResponse } from 'src/domain/models/common'
import { PostPlacementGetAccounts } from './urls'

export class PostPlacementData implements IPostPlacementGetOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async getAccounts(
    pagination: DataTableState,
    creditorId?: string,
    vendorId?: string
  ): Promise<DataResponse<Account>> {
    const encodedData = encodeURIComponent(JSON.stringify(pagination))
    const creditorFilter = creditorId ? `&creditorId= ${creditorId} ` : ''
    const vendorFilter = vendorId ? `&vendorId= ${vendorId} ` : ''
    const response = await this.httpClient.get(
      `${PostPlacementGetAccounts}?pagination=${encodedData}${vendorFilter}${creditorFilter}`
    )
    return response.data
  }
}
