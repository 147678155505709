import React from 'react'
import { makeFilesData } from 'src/main/factories/feature/get/Files'
import { makeAccountPostData } from 'src/main/factories/feature/post/Account'
import UpdateAccountFileValidation from 'src/presentation/pages/Accounts/UpdateAccounts/UpdateAccountFileValidation'

const makeUpdateAccountFileValidation: React.FC = () => {
  return (
    <UpdateAccountFileValidation
      fileOperations={makeFilesData()}
      accountPostOperations={makeAccountPostData()}
    />
  )
}

export default makeUpdateAccountFileValidation
