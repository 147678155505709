import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { IAccountPostOperations } from 'src/domain/features/post/account/account'
import {
  AccountFileData,
  OverloadedVendor,
  RecallAccountFileData,
  RecallBusinessAccount,
  UpdateAccountFileData,
} from 'src/domain/models/accounts'
import { ApiError } from 'src/domain/models/api/api'
import {
  AccountsCheckVendorAccountLoad,
  AccountsProcessAccountFile,
  AccountsProcessChargeOffAccounts,
  AccountsProcessRecallAccountFile,
  AccountsProcessRecallBusinessAccounts,
  AccountsProcessReturnAccountFile,
  AccountsProcessReturnBusinessAccounts,
  AccountsProcessSellAccounts,
  AccountsProcessSendAccountToStrategy,
  AccountsProcessUpdateAccountFile,
} from './urls'
import { FileValidationResult } from 'src/domain/models/file'

export class AccountPostData implements IAccountPostOperations {
  constructor(private readonly httpClient: IHttpClient) {}
  async processSellAccounts(
    creditorId: string,
    assetTypeName?: string,
    templateId?: string,
    portfolioTypeId?: string,
    cutOffDate?: Date,
    fileData?: string[],
    fileUploaded?: any,
    filterAccounts?: any
  ): Promise<ApiError | FileValidationResult[]> {
    const form = new FormData()
    form.append('creditorId', creditorId)
    form.append('assetTypeName', assetTypeName ?? '')
    form.append('templateId', templateId ?? '')
    form.append('portfolioTypeId', portfolioTypeId ?? '')
    form.append('cutOffDate', cutOffDate?.toDateString() ?? '')
    form.append('filterAccounts', JSON.stringify(filterAccounts ?? ''))
    form.append('accounts', JSON.stringify(Array.from(fileData ?? '')))
    form.append('fileUploaded', fileUploaded)
    return this.httpClient.post(AccountsProcessSellAccounts, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async processAccountFile(
    creditorId: string,
    strategyId: Number,
    fileData: AccountFileData[],
    fileUploaded: any,
    overloadedVendor: string[] | null,
    isRestingArea: boolean
  ): Promise<ApiError | FileValidationResult[]> {
    const form = new FormData()
    form.append('creditorId', creditorId)
    form.append('strategyId', strategyId ? strategyId.toString() : '')
    form.append('accounts', JSON.stringify(Array.from(fileData)))
    form.append('fileUploaded', fileUploaded)
    form.append(
      'overloadedVendors',
      overloadedVendor ? JSON.stringify(overloadedVendor) : ''
    )
    form.append('isRestingArea', isRestingArea.toString())
    return this.httpClient.post(AccountsProcessAccountFile, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
  processRecallAccountFile(
    creditorId: string,
    fileData: RecallAccountFileData[],
    fileUploaded: any
  ): Promise<FileValidationResult[] | ApiError> {
    const form = new FormData()
    form.append('creditorId', creditorId)
    form.append('recallAccounts', JSON.stringify(Array.from(fileData)))
    form.append('fileUploaded', fileUploaded)
    return this.httpClient.post(AccountsProcessRecallAccountFile, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  processReturnAccountFile(
    vendorId: string,
    fileData: RecallAccountFileData[],
    fileUploaded: any
  ): Promise<FileValidationResult[] | ApiError> {
    const form = new FormData()
    form.append('vendorId', vendorId)
    form.append('returnAccounts', JSON.stringify(Array.from(fileData)))
    form.append('fileUploaded', fileUploaded)
    return this.httpClient.post(AccountsProcessReturnAccountFile, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  processRecallBusinessAccounts(
    businessId: string,
    accounts: RecallBusinessAccount[]
  ): Promise<FileValidationResult[] | ApiError> {
    const form = new FormData()
    form.append('businessId', businessId)
    form.append('accounts', JSON.stringify(Array.from(accounts)))
    return this.httpClient.post(AccountsProcessRecallBusinessAccounts, form)
  }

  processReturnBusinessAccounts(
    businessId: string,
    accounts: RecallBusinessAccount[]
  ): Promise<FileValidationResult[] | ApiError> {
    const form = new FormData()
    form.append('businessId', businessId)
    form.append('accounts', JSON.stringify(Array.from(accounts)))
    return this.httpClient.post(AccountsProcessReturnBusinessAccounts, form)
  }

  checkVendorAccountLoad(
    strategyId: Number,
    fileData: AccountFileData[]
  ): Promise<OverloadedVendor[] | ApiError> {
    const form = new FormData()
    form.append('strategyId', strategyId.toString())
    form.append('accounts', JSON.stringify(Array.from(fileData)))
    return this.httpClient.post(AccountsCheckVendorAccountLoad, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async processUpdateAccountFile(
    creditorId: string,
    fileData: UpdateAccountFileData[],
    fileUploaded: any
  ): Promise<ApiError | FileValidationResult[]> {
    const form = new FormData()
    form.append('creditorId', creditorId)
    form.append('accounts', JSON.stringify(Array.from(fileData)))
    form.append('fileUploaded', fileUploaded)
    return this.httpClient.post(AccountsProcessUpdateAccountFile, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
  async processSendAccountToStrategy(
    creditorId: string,
    fileData?: string[],
    fileUploaded?: any,
    filterAccounts?: any,
    strategy?: any
  ): Promise<ApiError | FileValidationResult[]> {
    const form = new FormData()
    form.append('creditorId', creditorId)
    form.append('strategyId', strategy ?? '')
    form.append('filterAccounts', JSON.stringify(filterAccounts ?? ''))
    form.append('accounts', JSON.stringify(Array.from(fileData ?? '')))
    form.append('fileUploaded', fileUploaded)
    return this.httpClient.post(AccountsProcessSendAccountToStrategy, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async processChargeOffAccounts(
    creditorId: string,
    fileData?: string[],
    fileUploaded?: any,
    filterAccounts?: any
  ): Promise<ApiError | FileValidationResult[]> {
    console.log(filterAccounts)
    const form = new FormData()
    form.append('creditorId', creditorId)
    form.append('filterAccounts', JSON.stringify(filterAccounts ?? ''))
    form.append('accounts', JSON.stringify(Array.from(fileData ?? '')))
    form.append('fileUploaded', fileUploaded)
    return this.httpClient.post(AccountsProcessChargeOffAccounts, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}
