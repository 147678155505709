/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'

import {
  Box,
  Form,
  FormDropdown,
  FormInput,
  FormModalSubmitButton,
  useForm,
} from 'everchain-uilibrary'
import { useMessageSnackbar } from 'src/utils/notification'
import { Skeleton } from '@mui/material'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { Options } from 'src/utils/common'
import { OldAuthContext } from 'src/context/OldAuthenticationContext'
import { IStrategyPostOperations } from 'src/domain/features/post/strategy/strategy'
import { StrategyStageDistribution } from 'src/domain/models/strategy'
import { IStrategyGetOperations } from 'src/domain/features/get/strategy/strategy'
import { Business } from 'src/domain/models/business'
import { getValidation } from 'src/validation/utils'
import makeStrategyDistributionValidation from 'src/main/factories/validation/strategyStagesDistribution'
interface StrategyVendorParams {
  strategyId: number
  strategyStageId: number
  businessOperations?: IBusinessGetOperations
  onCompleted: () => void
  strategyPostOperations?: IStrategyPostOperations
  strategyGetOperations?: IStrategyGetOperations
  dynamicDistribution: boolean
  strategyStageDistribution?: any
}

const StrategyVendorForm: React.FC<StrategyVendorParams> = ({
  strategyId,
  strategyStageId,
  onCompleted,
  businessOperations,
  strategyPostOperations,
  strategyGetOperations,
  dynamicDistribution,
  strategyStageDistribution,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { showSuccessMessage } = useMessageSnackbar()
  const { userPermissions } = useContext(OldAuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const [vendors, setVendors] = useState<Business[]>()
  const [formStrategy] = useState<StrategyStageDistribution>({
    distribution: strategyStageDistribution?.distribution ?? 0,
    fee: strategyStageDistribution?.fee ?? undefined,
    id: strategyStageDistribution?.id ?? 0,
    vendorId: strategyStageDistribution?.vendorId ?? '',
    strategyStageId: strategyStageId,
    strategyId: strategyId,
  })
  const methods = useForm({ defaultValues: formStrategy })
  const [selectedVendorFee, setSelectedVendorFee] = useState<any>(
    strategyStageDistribution?.fee ?? ''
  )

  const getDistributionValidation = () => {
    return makeStrategyDistributionValidation(
      100 - (totalDistribution - orginalDistribution)
    )
  }

  const onSubmitStage = (data: any) => {
    setLoading(true)
    if (data.id > 0) {
      strategyPostOperations
        ?.updateStrategyStageDistribution(data)
        .then((response: any) => {
          showSuccessMessage('Strategy Distribution edited successfully')
          setLoading(false)
          onCompleted()
        })
        .catch((error) => {
          setLoading(false)
        })
    } else {
      strategyPostOperations
        ?.addStrategyStageDistribution(data)
        .then((response: any) => {
          showSuccessMessage('Strategy Distribution created successfully')
          setLoading(false)
          onCompleted()
        })
        .catch((error) => {
          setLoading(false)
        })
    }
  }

  const [totalDistribution, setTotalDistribution] = useState<any>()

  const [orginalDistribution] = useState<number>(
    strategyStageDistribution?.distribution ?? 0
  )

  const GetVendors = async () => {
    setLoading(true)
    try {
      setVendors(
        await businessOperations?.getVendorsDataByStrategyAndStage(
          strategyId,
          strategyStageId,
          formStrategy.id
        )
      )
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const GetTotalDistribution = async () => {
    setLoading(true)
    try {
      setTotalDistribution(
        await strategyGetOperations?.getTotalDistribution(
          strategyId,
          strategyStageId
        )
      )
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  useEffect(() => {
    setLoading(true)
    GetVendors()
    GetTotalDistribution()
  }, [])

  const getDropDownOptions = (objectData: any[]): Options[] => {
    let newOptions: Options[] = []

    objectData.map((item: any) =>
      newOptions.push({
        label: isInternal
          ? `${item.name} (${item.status}) - ${item.stageAssetTypeFee}%`
          : `${item.name} - ${item.stageAssetTypeFee}%`,
        value: item.id,
      })
    )
    return newOptions
  }

  const optionsVendor = getDropDownOptions(vendors || [])

  const handleSelectVendor = (props: any) => {
    if (!props?.value) {
      setSelectedVendorFee('')
    }

    const fee = vendors?.find((x) => x.id === props?.value)?.stageAssetTypeFee
    setSelectedVendorFee(fee)
    methods.setValue('vendorId', props?.value)
    methods.setValue('fee', fee)
  }

  const handleSelectDistribution = (props: any) => {
    if (props?.target?.valueAsNumber) {
      methods.setValue('distribution', props?.target?.valueAsNumber, {
        shouldValidate: true,
      })
    } else {
      methods.setValue('distribution', 0, {
        shouldValidate: true,
      })
    }
  }

  return (
    <Form
      initialValues={formStrategy}
      onSubmit={onSubmitStage}
      methods={methods}
    >
      <Box>
        <Box>
          {loading ? (
            <Skeleton width={'20rem'} height={'2rem'}></Skeleton>
          ) : (
            <FormDropdown
              id="vendor-dropdown"
              validation={getValidation(
                getDistributionValidation(),
                'vendorId'
              )}
              isLoading={loading}
              name="vendorId"
              placeholder="Vendor"
              width="20rem"
              options={optionsVendor}
              onChange={handleSelectVendor}
            />
          )}
        </Box>
        {!dynamicDistribution && (
          <Box mt={3}>
            <FormInput
              id="distribution-input"
              validation={getValidation(
                getDistributionValidation(),
                'distribution'
              )}
              isLoading={loading}
              width="20rem"
              placeholder="Distribution"
              name="distribution"
              disabled={loading}
              type="number"
              afterValue="%"
              onChange={handleSelectDistribution}
            />
          </Box>
        )}
        {selectedVendorFee && (
          <Box mt={3}>
            <FormInput
              id="fee-input"
              width="20rem"
              placeholder="Fee"
              name="fee"
              isLoading={loading}
              disabled={loading}
              type="number"
              afterValue="%"
              readOnly={true}
              value={selectedVendorFee}
            />
          </Box>
        )}
      </Box>
      <FormModalSubmitButton text="Save" disabled={loading || false} />
    </Form>
  )
}

export default StrategyVendorForm
