import { IHttpClient } from 'src/data/interfaces/feature/http/IHttpClient'
import { IFileGetOperations } from 'src/domain/features/get/file/file'
import {
  FileData,
  FileType,
  FileUri,
  GeneratedFile,
} from 'src/domain/models/file'
import {
  FileGetFile,
  FileGetFileTypes,
  FileGetTemplateFile,
  FileGetUri,
} from './urls'
import { DataTableState, IFileMap } from 'everchain-uilibrary'
import { DataResponse } from 'src/domain/models/common'
import { getCurrentPage } from 'src/utils/grid'
import { FileTypeEnum } from 'src/utils/constants'
import {
  getAccountFileMapRepository,
  getChargeOffAccountFileMapRepository,
  getRecallFileMapRepository,
  getTransactionFileMapRepository,
  getUpdateAccountFileMapRepository,
} from './FileMapRepository'

export class FileGetData implements IFileGetOperations {
  constructor(private readonly httpClient: IHttpClient) {}

  async getFileUri(fileLinkId: number): Promise<FileUri> {
    const response = await this.httpClient.get(
      `${FileGetUri}?fileLinkId=${fileLinkId}`
    )
    return response?.data?.fileUri
  }

  async getFileTypeData(): Promise<FileType[]> {
    const response = await this.httpClient.get(`${FileGetFileTypes}`)
    return response?.data?.fileTypes
  }

  async getFileData(
    pagination: DataTableState,
    creditorId?: string,
    vendorId?: string,
    dateFrom?: Date,
    dateTo?: Date,
    fileType?: Number
  ): Promise<DataResponse<FileData>> {
    const creditorFilter = creditorId ? `&creditorId= ${creditorId} ` : ''
    const vendorFilter = vendorId ? `&vendorId= ${vendorId} ` : ''
    const dateFromFilter = dateFrom ? `&dateFrom= ${dateFrom} ` : ''
    const dateToFilter = dateTo ? `&dateTo= ${dateTo} ` : ''
    const fileTypeFilter = fileType ? `&fileType= ${fileType} ` : ''
    const response = await this.httpClient.get(
      `${FileGetFile}?pageNumber=${getCurrentPage(pagination)}&pageSize=${
        pagination.take
      }${vendorFilter}${creditorFilter}${dateFromFilter}${dateToFilter}${fileTypeFilter}`
    )
    return response.data
  }

  async getTemplateFile(
    templateFileTypeEnum: number | undefined
  ): Promise<GeneratedFile> {
    const response = await this.httpClient.get(
      `${FileGetTemplateFile}?templateFileTypeEnum=${templateFileTypeEnum}`
    )
    return response?.data
  }
  getFileMap(fileMapType: FileTypeEnum): IFileMap {
    switch (fileMapType) {
      case FileTypeEnum.AccountFile:
        return getAccountFileMapRepository()
      case FileTypeEnum.RecallAccountFile:
        return getRecallFileMapRepository()
      case FileTypeEnum.TransactionFile:
        return getTransactionFileMapRepository()
      case FileTypeEnum.UpdateAccountFile:
        return getUpdateAccountFileMapRepository()
      case FileTypeEnum.ChargeOffAccounts:
        return getChargeOffAccountFileMapRepository()
      default:
        throw new Error(`Unsupported file map type: ${fileMapType}`)
    }
  }
}
