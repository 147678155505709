import { Box } from '@material-ui/core'
import { Content, Header } from 'everchain-uilibrary'
import React, { useContext, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import AccountDetailFeature from './components/AccountDetailFeature'
import { IAccountGetOperations } from 'src/domain/features/get/account/account'
import { useCustomQuery } from 'src/infra/reactQuery'
import { ACCOUNTS } from 'src/presentation/routes'
import { AccountDetailsData } from 'src/domain/models/accounts'
import { OldAuthContext } from 'src/context/OldAuthenticationContext'
import { useGetBusinessessId } from 'src/utils/user'
import { ITransactionPostOperations } from 'src/domain/features/post/transaction/transaction'
import { getStandardUri } from 'src/utils/common'

interface AccountDetailsProps {
  accountOperations: IAccountGetOperations
  transactionPostOperation: ITransactionPostOperations
}

const AccountDetails: React.FC<AccountDetailsProps> = ({
  accountOperations,
  transactionPostOperation,
}: AccountDetailsProps) => {
  const { ECAID, backUrl } = useParams<any>()
  const [account, setAccount] = useState<AccountDetailsData>()
  const history = useHistory()
  const { userPermissions, isCreditor } = useContext(OldAuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const businessIds = useGetBusinessessId()

  const {
    data: accountDetailsData,
    isError: isDetailsError,
    isFetching: isDetailsLoading,
  } = useCustomQuery<AccountDetailsData>(
    ['getAccountDetails'],
    async () => accountOperations?.getAccountDetails(ECAID, businessIds),
    { cacheTime: 0 }
  )

  const createCardTitle = () => {
    if (!account) return ''

    if (isInternal) return account?.lenderLoanID

    return `${account?.lastName}, ${account?.firstName?.charAt(0)} | ${
      account?.lenderLoanID
    }`
  }

  useMemo(() => {
    if (isDetailsError) {
      history.push(getStandardUri(ACCOUNTS))
    }
    if (!isDetailsLoading) {
      setAccount(accountDetailsData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDetailsLoading, isDetailsError])

  return (
    <Box>
      <Content>
        <Header
          title={createCardTitle()}
          customBackUrl={getStandardUri(backUrl)}
          style={{ padding: 20 }}
        />
        <AccountDetailFeature
          account={account}
          eCAID={ECAID}
          accountOperations={accountOperations}
          isLoading={isDetailsLoading}
          isCreditor={isCreditor}
          isInternal={isInternal}
          transactionPostOperation={transactionPostOperation}
        />
      </Content>
    </Box>
  )
}
export default AccountDetails
