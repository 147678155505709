import React from 'react'
import { makeAccountsData } from '../../feature/get/Accounts'
import { makeFilesData } from '../../feature/get/Files'
import { makeBusinessGetData } from '../../feature/get/Business'
import RestingArea from 'src/presentation/pages/RestingArea'
import { makeAssetTypesData } from '../../feature/get/AssetTypes'
import { makeStrategyData } from '../../feature/get/Strategy'
import { makeAccountPostData } from '../../feature/post/Account'
import { makePortfolioData } from '../../feature/get/Portfolio'

const makeRestingArea: React.FC = () => {
  return (
    <RestingArea
      accountOperations={makeAccountsData()}
      businessOperations={makeBusinessGetData()}
      fileOperations={makeFilesData()}
      assetTypeOperations={makeAssetTypesData()}
      strategyOperations={makeStrategyData()}
      accountPostOperations={makeAccountPostData()}
      portfolioGetOperations={makePortfolioData()}
    />
  )
}

export default makeRestingArea
