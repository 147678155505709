import React, { useContext } from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { OldAuthContext } from 'src/context/OldAuthenticationContext'
import { Permissions } from 'src/domain/models/permissions'

interface PrivitedRouteProps extends RouteProps {
  permissionValues: Permissions[]
}

const PrivitedRoute: React.FC<PrivitedRouteProps> = ({
  path,
  exact,
  permissionValues,
  ...rest
}: PrivitedRouteProps) => {
  const { isAuth } = useContext(OldAuthContext)

  if (permissionValues?.length === 0) return <></>
  if (isAuth()) {
    return <Route {...rest} path={path} exact={exact} />
  }
  return (
    <Route
      {...rest}
      path={path}
      exact={exact}
      component={() => <Redirect to="/" />}
    />
  )
}

export default PrivitedRoute
