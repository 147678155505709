/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { ModalDialog } from 'everchain-uilibrary'
import MediaFileForm from './MediaFileForm'
import { makeMediaUploadPostData } from 'src/main/factories/feature/post/MediaUpload'
import { mockMediaUploadGetOperations } from 'src/data/features/get/mediaUpload/mockMediaUpload'
import {
  mockGetAllMediaAccount,
  mockGetDownloadTemplateFile,
  mockGetManifestTemplateFile,
  mockManifestBlobUrls,
} from 'src/domain/models/mediaUpload/mock_mediaUpload'

interface UploadModalProps {
  open: boolean
  setOpenUploadModal: any
}

const UploadModal: React.FC<UploadModalProps> = ({
  open,
  setOpenUploadModal,
}: UploadModalProps) => {
  return (
    <>
      <ModalDialog
        isOpen={open}
        hideOkButton={true}
        hideCancelButton={true}
        onClose={() => {
          setOpenUploadModal(false)
        }}
        header="Bulk Media Files Upload"
        style={{ minWidth: '450px', minHeight: '300px' }}
      >
        <MediaFileForm
          mediaUploadGetOperations={mockMediaUploadGetOperations(
            mockGetManifestTemplateFile,
            mockManifestBlobUrls,
            mockGetDownloadTemplateFile,
            mockGetAllMediaAccount
          )}
          mediaUploadPostOperations={makeMediaUploadPostData()}
        ></MediaFileForm>
      </ModalDialog>
    </>
  )
}

export default UploadModal
