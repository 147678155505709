import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  createContext,
  useContext,
  useReducer,
} from 'react'

import AuthService from 'src/configs/AuthService'
import { updateUserType } from 'src/data/features/post/permission'
import {
  CLIENT_INFO,
  INTERNAL,
  IS_RESELLER,
  TENANT_TYPE,
  BUSINESS_INFO,
  CONTROL_PANEL_PERMISSION_TOKEN,
  PermissionCodeAccess,
} from 'src/utils/constants'
import {
  Action,
  IAuthContext,
  BusinessType,
  Permissions,
  SetBusinessTypeAction,
  State,
} from './Interfaces'
import { Business, UserPermissions } from './AuthenticationContext'
import { useCustomQuery } from 'src/infra/reactQuery'
import { CONTROLPANEL_URL } from 'src/infra/http/httpClient'
import { GetAccessToken } from 'src/utils/helper'
import { permissionReduce } from 'src/utils/common'
import axios from 'axios'

interface AuthProviderProps {
  children: React.ReactNode
}

export const OldAuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  userPermissions: {
    type: 'Buyer',
    isReseller: false,
  },
  profileBusiness: [],
  profileClient: [],
  managePermissions: [],
  selectedType: '',
  isVendor: false,
  isCreditor: false,
  dispatch: () => {},
  login: () => {},
  logout: () => {},
  renewToken: () => {},
  isAuth: (): boolean => false,
  getPermissionsByModule: (): Permissions[] => [],
})

const businessTypeReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_TYPE':
      return {
        ...state,
        selectedType: action.payload,
        isVendor: action.payload === 'vendor',
        isCreditor: action.payload === 'creditor',
      }
    default:
      return state
  }
}

const findBusinessType = (userType: string): BusinessType => {
  const lowerCaseUserType = userType.toLowerCase()
  switch (lowerCaseUserType) {
    case 'internal':
      return 'internal'
    case 'buyer':
    case 'seller':
      return 'creditor'
    default:
      return 'vendor'
  }
}

export const setBusinessType =
  (dispatch: React.Dispatch<SetBusinessTypeAction>) => (type: BusinessType) => {
    dispatch({ type: 'SET_TYPE', payload: type })
    localStorage.setItem('selectedBusinessType', type)
  }

export const useAuth = (): IAuthContext => useContext(OldAuthContext)

const serv = new AuthService()

if (
  window.location.href.indexOf('signin-callback') === -1 &&
  window.location.pathname &&
  window.location.pathname.length > 1
) {
  localStorage.setItem('originationURI', window.location.href)
}

export const OldAuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

  const [user, setUser] = useState<Oidc.User | undefined>(undefined)
  const [profileBusiness, setProfileBusiness] = useState<Business[]>([])
  const [profileClient, setProfileClient] = useState([])
  const [permissionCodes, setPermissionCodes] = useState<any[]>([])
  const [encodedPermissions, setEncodedPermissions] = useState('')
  const [managePermission, setManagePermission] = useState<Permissions[]>([])
  const [managePermissionReduce, setManagePermissionReduce] = useState<any>()
  const [userPermissions, setPermissions] = useState<UserPermissions>({
    type: 'Buyer',
    isReseller: false,
  })
  const [authService, setAuthService] = useState<AuthService>()
  const [managePermissions, setManagePermissions] = useState<Permissions[]>([])

  const userToken = GetAccessToken()

  const {
    data: permissionData,
    isLoading,
    isFetched: isFechted,
  } = useCustomQuery<any>(
    ['permissionsAuth', permissionCodes, encodedPermissions],
    async () => {
      return axios({
        method: 'post',
        url: `${CONTROLPANEL_URL}/permissions.checkpermission`,
        data: {
          permissionCodes,
          encodedPermissions,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
    { enabled: !!(permissionCodes && encodedPermissions) }
  )

  useEffect(() => {
    if (isFechted && permissionData && !isLoading) {
      const permissions = permissionData?.permissions
      setManagePermission(permissions)
      setManagePermissionReduce(permissionReduce(permissions))
    }
  }, [isFechted, permissionData, isLoading])

  useEffect(() => {
    const { profile }: any = user || {}
    if (user && profile) {
      const permissionCode: any[] = Object.entries(PermissionCodeAccess).map(
        ([pName, pCode]) => pCode
      )
      setPermissionCodes(permissionCode)
      setEncodedPermissions(profile[CONTROL_PANEL_PERMISSION_TOKEN])
    }
  }, [user])

  useEffect(() => {
    if (managePermissionReduce) {
      setPermissions({
        ...userPermissions,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managePermission])

  const getAuthServices = useCallback((): AuthService => {
    if (authService === undefined) {
      throw new Error('Authentication services not available')
    }
    return authService
  }, [authService])

  const w = window as any
  w.hsConversationsOnReady = [() => {}]

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      const services = serv

      setAuthService(services)
      try {
        const userData: any = await services.getUser()

        if (userData) {
          setUser(userData)
          setIsAuthenticated(true)
          const profileTenantType = userData.profile[TENANT_TYPE]
          const profileClientInfo = userData.profile[CLIENT_INFO] || null
          const profileBusinessInfo = userData.profile[BUSINESS_INFO] || null

          const profileClientInfoParse = Array.isArray(profileClientInfo)
            ? JSON.parse(profileClientInfo[0])
            : JSON.parse(profileClientInfo)
          if (profileTenantType.Type !== INTERNAL)
            setProfileClient(profileClientInfoParse)

          const isArray = Array.isArray(profileBusinessInfo)
          if (profileBusinessInfo && isArray) {
            const businessParse = profileBusinessInfo.map((item: any) => {
              const itemParse = JSON.parse(item || null)
              return itemParse
            })
            setProfileBusiness(businessParse)
          } else if (profileBusinessInfo && !isArray) {
            const businessItem: any = []
            businessItem.push(JSON.parse(profileBusinessInfo))
            setProfileBusiness(businessItem)
          }

          const isReseller = userData.profile[IS_RESELLER]
            ? JSON.parse(userData.profile[IS_RESELLER]?.toLowerCase())
            : false
          const type = profileClientInfoParse
            ? profileClientInfoParse.Type
            : profileTenantType
          setPermissions({
            type,
            isReseller,
          })

          updateUserType(type)

          localStorage.removeItem('originationURI')
        } else {
          await services.logoutCallback()
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        // console.log(error.toString())
      }
    }

    initAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserType])

  const storedSelectedType = localStorage.getItem(
    'selectedBusinessType'
  ) as BusinessType

  const [initialSelectedType] = useState(
    storedSelectedType ||
      (userPermissions ? findBusinessType(userPermissions.type) : '')
  )

  useEffect(() => {
    if (isFechted) {
      const businessType: BusinessType =
        storedSelectedType || findBusinessType(userPermissions.type)
      if (businessType) {
        localStorage.setItem('selectedBusinessType', businessType)
      }

      dispatch({ type: 'SET_TYPE', payload: businessType })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFechted])

  const [state, dispatch] = useReducer(businessTypeReducer, {
    selectedType: initialSelectedType,
    isVendor: initialSelectedType === 'vendor',
    isCreditor: initialSelectedType === 'creditor',
  })

  const values = useMemo(
    () => ({
      ...state,
      isAuthenticated,
      user,
      userPermissions,
      profileBusiness,
      profileClient,
      managePermissions,
      dispatch,
      login: (): void => {
        getAuthServices().login()
      },
      logout: (): void => {
        window.localStorage.removeItem('selectedBusinessType')
        getAuthServices().logout()
      },
      renewToken: (): void => {
        getAuthServices().renewToken()
      },
      isAuth: (): boolean => {
        return AuthService.isAuth()
      },
      handleSetManagePermissions: (permissions: Permissions[]): void => {
        setManagePermissions(permissions)
      },
      getPermissionsByModule: (moduleId?: string): Permissions[] => {
        if (moduleId == null) {
          return managePermissions
        }
        return managePermissions?.filter((x) => x.code?.startsWith(moduleId))
      },
    }),
    [
      isAuthenticated,
      user,
      userPermissions,
      profileBusiness,
      getAuthServices,
      profileClient,
      managePermissions,
      state,
    ]
  )

  return (
    <OldAuthContext.Provider value={values}>{children}</OldAuthContext.Provider>
  )
}
