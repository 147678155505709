import { DataTable, DataTableState, renderDate } from 'everchain-uilibrary'
import React, { useContext, useEffect, useState } from 'react'
import { OldAuthContext } from 'src/context/OldAuthenticationContext'
import { IAccountGetOperations } from 'src/domain/features/get/account/account'

interface ChainOfPlacementProps {
  eCAID: string
  accountOperations: IAccountGetOperations
  businessIds: any
  isCardLoading: boolean
}

const ChainOfPlacement: React.FC<ChainOfPlacementProps> = ({
  eCAID,
  accountOperations,
  businessIds,
  isCardLoading,
}: ChainOfPlacementProps) => {
  const [form, setForm] = useState<any>({
    chainOfPlacements: [],
    totalChainOfPlacements: 0,
    isLoading: false,
  })

  const { isCreditor } = useContext(OldAuthContext)
  var businessId = isCreditor ? businessIds?.creditorId : businessIds?.vendorId
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 100,
    filter: undefined,
    sort: undefined,
  })

  const fetchChainOfPlacement = async (gridParams: DataTableState) => {
    try {
      setForm((prevObj: any) => {
        return { ...prevObj, isLoading: true }
      })
      const dataResponse = await accountOperations?.getAccountChainOfPlacement(
        gridParams,
        eCAID,
        isCreditor,
        businessId || null
      )

      setForm((prevObj: any) => {
        return {
          ...prevObj,
          chainOfPlacements: dataResponse?.data,
          totalChainOfPlacements: dataResponse?.totalCount ?? 0,
          isLoading: false,
        }
      })
    } catch (err) {
      console.log(err)
      setForm((prevObj: any) => {
        return { ...prevObj, isLoading: false }
      })
    }
  }

  const columns = [
    {
      field: 'recordType',
      title: 'Record Type',
      show: true,
      width: 50,
    },
    { field: 'business', title: 'Business', show: true, width: 50 },
    {
      field: 'createdDate',
      title: 'Created Date',
      show: true,
      width: 50,
      render: renderDate,
    },
    {
      field: 'consumerPaymentInstruction',
      title: 'Consumer Payment Instruction',
      show: true,
      width: 100,
    },
  ]

  useEffect(() => {
    if (businessId || !isCardLoading) {
      fetchChainOfPlacement(gridState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, isCardLoading])

  return (
    <div data-test-id="chainOfPlacement">
      <DataTable
        isLoading={form.isLoading || isCardLoading}
        height="100%"
        maxHeight="100%"
        gridColumns={columns || []}
        gridState={gridState}
        data={form.chainOfPlacements}
        pageable={true}
        total={form.totalChainOfPlacements}
        onDataStateChange={(e) => {
          setGridState(e.dataState)
          if (form.chainOfPlacements) {
            fetchChainOfPlacement(e.dataState)
          }
        }}
      />
    </div>
  )
}

export default ChainOfPlacement
