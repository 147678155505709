import React, { useContext, useEffect } from 'react'
import { authUrl } from 'src/configs/authConst'
import { USER_ID } from 'src/configs/AuthService'

import { useMessageSnackbar } from 'src/utils/notification'
import { OldAuthContext } from 'src/context/OldAuthenticationContext'

interface PasswordExpiringNotificationProps {
  children: React.ReactNode
}

const PasswordExpiringNotification: React.FC<
  PasswordExpiringNotificationProps
> = ({ children }) => {
  const { user } = useContext(OldAuthContext)

  const { showWarningMessage } = useMessageSnackbar()
  useEffect(() => {
    if (user) {
      const url = `${authUrl}/account/IsPasswordExpiring?userId=${user.profile[USER_ID]}`
      fetch(url, {
        method: 'GET',
      })
        .then((res) => {
          if (res.ok) return res.json()

          const err = new Error(res.statusText)
          throw err
        })
        .then((body) => {
          if (body.isPasswordExpiring)
            showWarningMessage(
              `Your password will expire in ${body.expiringIn} days.`
            )
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log('Unable to get password information'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return <>{children}</>
}

export default PasswordExpiringNotification
