import React, { memo, useContext, useState } from 'react'
import { OldAuthContext, useAuth } from 'src/context/AuthenticationContext'
import Menus from './Menus'
import { Root } from './styles'
import { Box, Footer, Main, MainContent, AppBar } from 'everchain-uilibrary'
import { getStandardUri, isUkCountry } from 'src/utils/common'
import { APP_NAME } from 'src/utils/constants'
import { searchFilterTypeDescription } from 'src/domain/models/search'
import { useHistory } from 'react-router-dom'
import { SEARCH } from 'src/presentation/routes'
import ToolbarMain from './ToolbarMain'

interface Props {
  children: React.ReactNode
}

const userId = window.localStorage.getItem('userId')
const { origin } = window.location
const changePasswordlink = `${process.env.REACT_APP_AUTHORITY_URL}/account/ChangePassword?userid=${userId}&returnurl=${origin}`

const MainLayout: React.FC<Props> = ({ children }: Props) => {
  const history = useHistory()
  const authenticationObj = useAuth()
  const [logoutCooldown, setLogoutCooldown] = useState(false)
  const { profileClient } = useContext(OldAuthContext)
  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )

  const handleChangepassword = (): void => {
    window.open(changePasswordlink, '_blank')
  }

  const handleOnLogout = (): void => {
    if (!logoutCooldown) {
      setLogoutCooldown(true)
      authenticationObj.logout()
      setTimeout(() => {
        setLogoutCooldown(false)
      }, 6000)
    }
  }

  const handleRedirectSearch = (search: any) => {
    history.push(
      getStandardUri(
        `${SEARCH}/1/${search.numberOfRecords}/${search.query}/${search.filter.id}`
      )
    )
  }

  return (
    <Root>
      <ToolbarMain showSearchBar={true} />
      <Box display="flex" flexDirection="row" height="inherit">
        <Menus />
        <Main>
          <MainContent>{children}</MainContent>
          <Footer isUk={isUk} />
        </Main>
      </Box>
    </Root>
  )
}

export default memo(MainLayout)
