import { IMediaUploadGetOperations } from 'src/domain/features/get/mediaUpload/mediaUpload'
import { ManifestBlobUrls, TemplateFile } from 'src/domain/models/mediaUpload'

export const mockMediaUploadGetOperations = (
  mockManifestTemplateFile: TemplateFile,
  mockMediaFileBlobUrl: ManifestBlobUrls,
  mockManifestDownloadFile: TemplateFile,
  mockAllAccountMedia: TemplateFile
): IMediaUploadGetOperations => {
  return {
    getManifestTemplateFile: async () => {
      return mockManifestTemplateFile
    },
    getMediaFileBlobUrl: async () => {
      return mockMediaFileBlobUrl
    },
    getDownloadMediaTemplateFile: async () => {
      return mockManifestDownloadFile
    },
    getAllAccountMedia: async () => {
      return mockAllAccountMedia
    },
  }
}
