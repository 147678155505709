import React from 'react'
import BulkPostPlacement from 'src/presentation/pages/BulkPostPlacement'
import { makeBusinessGetData } from '../../feature/get/Business'
import { makePostPlacementGetData } from '../../feature/get/PostPlacement'

const makeAccounts: React.FC = () => {
  return (
    <BulkPostPlacement
      businessOperations={makeBusinessGetData()}
      postPlacementOperations={makePostPlacementGetData()}
    />
  )
}

export default makeAccounts
