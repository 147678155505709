/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useContext, useState } from 'react'
import {
  Box,
  Button,
  Content,
  Flex,
  Grid,
  Group,
  Input,
  Typography,
  Dropdown,
  DataTableState,
  DataTable,
  Checkbox,
} from 'everchain-uilibrary'
import { OldAuthContext } from 'src/context/OldAuthenticationContext'
import Creditors from '../Accounts/components/Creditors'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import PostPlacementTypes from '../Accounts/components/PostPlacementTypes'
import { profileCountry, useGetBusinessessId } from 'src/utils/user'
import { useCustomQuery } from 'src/infra/reactQuery'
import { IPostPlacementGetOperations } from 'src/domain/features/get/postPlacement/postPlacement'
import { Account } from 'src/domain/models/accounts'
import { TdNoEllipsis } from 'src/presentation/styles/layout'
import { useMutation } from '@tanstack/react-query'

interface BulkPostPlacementParams {
  businessOperations?: IBusinessGetOperations
  postPlacementOperations?: IPostPlacementGetOperations
}

export interface FormFilter {
  creditorId: string | undefined
  vendorId: string | undefined
  last4SSN: string | undefined
  accountIDs: string | undefined
  postPlacementType: string | undefined
}

interface Options {
  label: string
  value: string
}

const Accounts: React.FC<BulkPostPlacementParams> = ({
  businessOperations,
  postPlacementOperations,
}) => {
  const { userPermissions } = useContext(OldAuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const businessId = useGetBusinessessId()
  const { isVendor, isCreditor } = useContext(OldAuthContext)
  const operationType = isVendor ? 2 : 1
  const [accountsSelected, setAccountsSelected] = useState<string[]>([])
  const [fetchingAccounts, setIsFetchingAccounts] = useState<boolean>(false)

  const getPersistedAccountFilterData = () => {
    if (window.localStorage.getItem('accountsFilterStorage') !== null) {
      return JSON.parse(
        window.localStorage.getItem('accountsFilterStorage') ?? ''
      )
    }
  }

  const [form, setForm] = useState<FormFilter | undefined>(
    getPersistedAccountFilterData()
  )

  const [lastFilterUsed, setLastFilterUsed] = useState<FormFilter | undefined>(
    form
  )

  const getPersistedAccountData = () => {
    if (window.localStorage.getItem('accountsFiltered') !== null) {
      return JSON.parse(window.localStorage.getItem('accountsFiltered') ?? '')
    }
  }

  const [accounts, setAccounts] = useState<Account[] | undefined>(
    getPersistedAccountData()?.accounts || []
  )

  const [totalAccounts, setTotalAccounts] = useState<number>(
    getPersistedAccountData()?.totalCount | 0
  )

  const { data, isLoading: loadingAgencies } = useCustomQuery(
    ['getPlacedAccounts', form?.creditorId],
    async () =>
      businessOperations?.getPlacedBusiness(
        [form?.creditorId || ''],
        operationType
      ),
    { cacheTime: 0, enabled: !!form?.creditorId }
  )

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const mutationGetAccounts = useMutation({
    mutationFn: async () => {
      setIsFetchingAccounts(true)
      return postPlacementOperations?.getAccounts(
        gridState,
        form?.creditorId,
        form?.vendorId
      )
    },
    onSuccess: async (response: any) => {
      setIsFetchingAccounts(false)
      setLastFilterUsed(form)
      setAccounts(response?.data)
      setTotalAccounts(response?.totalCount ?? 0)
      window.localStorage.setItem(
        'accountsFiltered',
        JSON.stringify({
          accounts: response?.data,
          totalCount: response?.totalCount,
        })
      )
    },
    onError: async (response: any) => {
      setIsFetchingAccounts(false)
    },
  })

  const handleFilterClick = () => {
    mutationGetAccounts.mutate()
  }

  const getDropDownOptions = (objectData: any[]): Options[] => {
    let newOptions: Options[] = []

    objectData.map((item: any) =>
      newOptions.push({
        label: item.businessName,
        value: item.businessId,
      })
    )
    return newOptions
  }

  const handleCreditorsUpdate = (creditor: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, creditorId: creditor }
    })
  }

  const handleTypessUpdate = (type: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, type: type }
    })
  }

  const handleAgencyUpdate = (vendor: any) => {
    setForm((prevObj: any) => {
      return { ...prevObj, vendorId: vendor }
    })
  }

  const handleAddCheck = (props: any) => {
    if (
      accountsSelected.find((id) => id === props.dataItem['ecaid']) !==
      undefined
    )
      return true

    return false
  }

  const options = getDropDownOptions(data?.businessList || [])

  const GridAccountsColumns = () => {
    return [
      {
        title: 'Select',
        width: 80,
        show: true,
        notFilterable: true,
        render: (props: any) => {
          return (
            <TdNoEllipsis>
              <Checkbox
                onChange={(event: any) => {
                  if (event) {
                    setAccountsSelected([
                      ...accountsSelected,
                      props.dataItem['ecaid'],
                    ])
                  } else {
                    setAccountsSelected(
                      accountsSelected.filter(
                        (id) => id !== props.dataItem['ecaid']
                      )
                    )
                  }
                }}
                checked={handleAddCheck(props)}
              />
            </TdNoEllipsis>
          )
        },
      },
      {
        field: 'lenderLoanId',
        title: 'Loan ID',
        show: true,
        width: 200,
      },
      {
        field: 'firstName',
        title: 'First Name',
        width: 150,
        show: !isInternal,
      },
      {
        field: 'lastName',
        title: 'Last Name',
        width: 150,
        show: !isInternal,
      },
      { field: 'strategyName', title: 'Strategy', show: true, width: 150 },
      {
        field: 'daysInStrategy',
        title: 'Days in Strategy',
        show: true,
        width: 150,
        filter: 'numeric',
      },
      {
        field: 'stageName',
        title: 'Stage',
        show: true,
        width: 150,
      },
      {
        field: 'daysInStage',
        title: 'Days in Stage',
        show: true,
        width: 150,
        filter: 'numeric',
      },
      {
        field: 'ecaid',
        title: 'ECAID',
        show: true,
        width: 250,
      },
    ]
  }

  return (
    <Content id="accounts">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '5vh',
        }}
      >
        <Typography variant="h5">Bulk Post Placement</Typography>
      </div>
      <Flex justifyContent={'space-between'}>
        <Group>
          <>
            <Creditors
              businessOperations={businessOperations}
              onCreditorsUpdate={handleCreditorsUpdate}
              selectedCreditor={getPersistedAccountFilterData()?.creditorId}
            />
            <Dropdown
              id="agency-dropdown"
              maxwidth="300px"
              width="300px"
              options={options}
              value={businessId[0] || undefined}
              onChange={(option?: Options) => {
                handleAgencyUpdate(option?.value)
              }}
              disabled={!form?.creditorId}
              placeholder="Agency"
              isLoading={loadingAgencies && !!form?.creditorId}
            />
            <Input placeholder="Last 4SSN" variant="secondary" />
            <Input placeholder="Account IDs" variant="secondary" />
          </>
        </Group>
        <Button
          useRipple
          width={80}
          height={40}
          disabled={
            !isInternal &&
            ((isCreditor && form?.creditorId == null) ||
              (isVendor && form?.vendorId == null))
          }
          onClick={handleFilterClick}
        >
          Filter
        </Button>
      </Flex>
      <Box mt={5}>
        <Grid item style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <Box>
            <Typography style={{ marginBottom: 0 }}>
              Total of accounts selected: 0
            </Typography>
          </Box>
          <Box>
            <PostPlacementTypes
              businessOperations={businessOperations}
              onTypesUpdate={handleTypessUpdate}
              selectedType={getPersistedAccountFilterData()?.type}
            ></PostPlacementTypes>
          </Box>
        </Grid>
      </Box>
      <Box mt={5}>
        <DataTable
          sortable={true}
          useFilterMenu={true}
          isLoading={fetchingAccounts}
          height="100%"
          maxHeight="100%"
          gridColumns={GridAccountsColumns() || []}
          gridState={gridState}
          data={accounts}
          pageable={true}
          total={totalAccounts}
          onDataStateChange={(e: { dataState: any }) => {
            setGridState(e.dataState)
            mutationGetAccounts.mutate()
          }}
        />
      </Box>
    </Content>
  )
}

export default Accounts
