import React, { useContext, useEffect, useState } from 'react'
import { OldAuthContext } from 'src/context/OldAuthenticationContext'
import { IBusinessGetOperations } from 'src/domain/features/get/business/business'
import { Business } from 'src/domain/models/business'
import { useMessageSnackbar } from 'src/utils/notification'
import {
  Box,
  Form,
  FormDropdown,
  FormInput,
  FormSubmitButton,
  Typography,
  useForm,
  ValidationRulesDictionary,
} from 'everchain-uilibrary'
import { SFTPServiceProvider } from 'src/utils/constants'
import { refetchQueriesWrapper, useCustomQuery } from 'src/infra/reactQuery'
import { IAuthContext } from 'src/context/Interfaces'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { IBusinessPostOperations } from 'src/domain/features/post/business/business'
import { getValidation } from 'src/validation/utils'

interface BusinessSettingsModalProps {
  selectedBusiness?: any
  businessGetOperations?: IBusinessGetOperations
  businessPostOperations?: IBusinessPostOperations
  validation?: ValidationRulesDictionary | undefined
  setOpenSettingModal: (value: any) => void
}
interface BusinessSettings {
  businessId: string
  sftpRootDirectory: string
  sftpServiceProvider: string
}
const initialValuesSettingsDefault: BusinessSettings = {
  businessId: '',
  sftpRootDirectory: '',
  sftpServiceProvider: 'ExaVault',
}

const BusinessSettingModal: React.FC<BusinessSettingsModalProps> = ({
  selectedBusiness,
  businessGetOperations,
  businessPostOperations,
  validation,
  setOpenSettingModal,
}: BusinessSettingsModalProps) => {
  const queryClient = useQueryClient()
  const { showSuccessMessage, showErrorMessage } = useMessageSnackbar()
  const [editMod, setEditMod] = useState<boolean>(false)
  const [businessSettings] = useState<any>(selectedBusiness)
  const [businesses, setBusinesses] = useState<Business[]>([])
  const { userPermissions } = useContext<IAuthContext>(OldAuthContext)
  const [loading, setLoading] = useState<boolean>(false)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const methods = useForm({ defaultValues: initialValuesSettingsDefault })

  const setFormBusinessValues = (resultData: any) => {
    methods.setValue('businessId', resultData.businessId)
    methods.setValue('sftpRootDirectory', resultData.sftpRootDirectory)
    methods.setValue('sftpServiceProvider', resultData.sftpServiceProvider)
  }

  const { isFetching: getCreditorsQueryLoading } = useCustomQuery(
    ['getBusinessWithoutBusinessSettings'],
    async () =>
      businessGetOperations
        ?.getBusinessWithoutBusinessSettings()
        .then((result: any) => {
          const mappedData: Business[] = result.map((item: any) => ({
            id: item.id,
            name: item.name,
          }))
          setBusinesses(mappedData)
          console.log(mappedData)
          return result
        })
        .catch((error: any) => {
          showErrorMessage('Error when fetching vendors')
        }),
    { cacheTime: 0 }
  )

  const createBusinessSettings = useMutation({
    mutationFn: async (params: any) => {
      return businessPostOperations?.createBusinessSettings(params)
    },
    onSuccess: (data: any) => {
      refetchQueriesWrapper(['getBusinessesSettings'], queryClient)
      setLoading(false)
      showSuccessMessage('Record created successfully')
      setOpenSettingModal(false)
    },
    onError: () => {
      showErrorMessage('Error when saving business settings')
      setLoading(false)
    },
  })

  const editBusinessSettings = useMutation({
    mutationFn: async (params: any) => {
      return businessPostOperations?.editBusinessSettings(params)
    },
    onSuccess: (data: any) => {
      refetchQueriesWrapper(['getBusinessesSettings'], queryClient)
      setLoading(false)
      showSuccessMessage('Record edited successfully')
      setOpenSettingModal(false)
    },
    onError: () => {
      showErrorMessage('Error when editing business settings')
      setLoading(false)
    },
  })

  useEffect(() => {
    console.log(selectedBusiness)
    console.log(businesses)
    if (selectedBusiness) {
      setEditMod(true)
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness])

  const onSubmitConfiguration = async (formData: any) => {
    setLoading(true)
    const createRequest = {
      sftpRootDirectory: formData.sftpRootDirectory,
      businessId: formData.businessId,
      businessName: businesses.find((x) => x.id === formData.businessId)?.name,
      sftpServiceProvider: formData.sftpServiceProvider,
    }

    if (!selectedBusiness) {
      await createBusinessSettings.mutateAsync(createRequest)
    } else {
      await editBusinessSettings.mutateAsync(createRequest)
    }
  }

  useEffect(() => {
    if (businessSettings && businesses.length > 0) {
      setFormBusinessValues(businessSettings)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessSettings, businesses])

  return (
    <Form
      methods={methods}
      initialValues={initialValuesSettingsDefault}
      onSubmit={onSubmitConfiguration}
    >
      <Box display="flex" flexDirection="column" width="100%">
        <Box mb={2}>
          {editMod ? (
            <Typography>{selectedBusiness?.businessName}</Typography>
          ) : (
            <FormDropdown
              isLoading={loading || getCreditorsQueryLoading}
              placeholder="Business"
              id="select-business"
              name="businessId"
              options={businesses}
              disabled={editMod}
              width={'100%'}
              labelOptionName="name"
              valueOptionName="id"
              isFetching={loading || getCreditorsQueryLoading}
              validation={getValidation(validation, 'businessId')}
            />
          )}
        </Box>
        <Box mb={2}>
          <FormInput
            isLoading={loading || getCreditorsQueryLoading}
            name="sftpRootDirectory"
            id="sftpRootDirectory"
            placeholder="SFTP Root Directory"
            width={'100%'}
            maxLength={30}
            isFetching={loading || getCreditorsQueryLoading}
          />
        </Box>
        {isInternal && (
          <Box mb={2}>
            <FormDropdown
              isLoading={loading || getCreditorsQueryLoading}
              validation={getValidation(validation, 'sftpServiceProvider')}
              name="sftpServiceProvider"
              placeholder="SFTP Service Provider"
              options={SFTPServiceProvider?.sort((a: any, b: any) =>
                a.name.localeCompare(b.name)
              )}
              valueOptionName="id"
              labelOptionName="name"
              width={'100%'}
              isFetching={loading || getCreditorsQueryLoading}
            />
          </Box>
        )}
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <FormSubmitButton
            disabled={loading || getCreditorsQueryLoading}
            isFetching={loading || getCreditorsQueryLoading}
            text="Save"
          />
        </Box>
      </Box>
    </Form>
  )
}

export default BusinessSettingModal
