/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

import {
  Form,
  FormInput,
  FormDropdown,
  Box,
  FormModalSubmitButton,
  useForm,
} from 'everchain-uilibrary'
import { ValidationRulesDictionary } from 'src/validation/validation'
import { getValidation } from 'src/validation/utils'
import { useMessageSnackbar } from 'src/utils/notification'
import { StrategyStage } from 'src/domain/models/strategy'
import { IStrategyPostOperations } from 'src/domain/features/post/strategy/strategy'
import { CHARGE_OFF_STRATEGY_STAGE, orders } from 'src/utils/constants'
import { Skeleton } from '@mui/material'
import { Options } from 'src/utils/common'

interface StrategyParams {
  id: number
  strategyStagesPostOperations?: IStrategyPostOperations
  strategyStageValidation?: ValidationRulesDictionary | undefined
  ordersCreated: number[]
  onCompleted: () => void
  isEditing?: boolean
  editStrategyData?: StrategyStage | undefined
}

const StrategyStageForm: React.FC<StrategyParams> = ({
  id,
  strategyStageValidation,
  strategyStagesPostOperations,
  ordersCreated,
  onCompleted,
  isEditing = false,
  editStrategyData,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { showSuccessMessage } = useMessageSnackbar()

  const getNextAvailableOrder = (): Options[] => {
    const stages: Options[] = []
    const chargeOffIncluded = ordersCreated.includes(5)
    const nextStage = orders.find(
      (order) => !ordersCreated.includes(Number(order.value))
    )
    if (nextStage !== undefined) {
      stages.push(nextStage)
      if (!chargeOffIncluded && Number(nextStage?.value) > 0) {
        stages.push(CHARGE_OFF_STRATEGY_STAGE)
      } else if (chargeOffIncluded) {
        return orders.filter((order) => order.value === '6')
      }
    }

    return stages
  }

  const onSubmitStage = (data: any) => {
    setLoading(true)
    const newStrategyStage: StrategyStage = {
      description: isEditing
        ? data.description
        : orders.find((order) => order.value === data.order)
        ? orders.find((order) => order.value === data.order)?.label
        : CHARGE_OFF_STRATEGY_STAGE.value === data.order
        ? CHARGE_OFF_STRATEGY_STAGE.label
        : '',
      duration: data.duration,
      expanded: false,
      id: isEditing ? editStrategyData?.id || 0 : 0,
      order: Number(data.order),
      strategyId: id,
    }

    const savePromise = isEditing
      ? strategyStagesPostOperations?.editStrategyStages(newStrategyStage)
      : strategyStagesPostOperations?.addStrategyStages(newStrategyStage)

    savePromise
      ?.then(() => {
        showSuccessMessage(
          `Strategy stage ${isEditing ? 'updated' : 'created'} successfully`
        )
        setLoading(false)
        onCompleted()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const [formStrategy, setFormStrategy] = useState<StrategyStage>({
    description: isEditing ? editStrategyData?.description || '' : '',
    duration: isEditing ? editStrategyData?.duration : undefined,
    expanded: false,
    id: isEditing ? editStrategyData?.id : 0,
    order: undefined,
    strategyId: id,
  })

  const methods = useForm()

  useEffect(() => {
    setFormStrategy((prevState: any) => ({
      ...prevState,
      strategyId: id,
    }))
    if (isEditing && editStrategyData) {
      methods.setValue('order', editStrategyData.order)
      methods.setValue('description', editStrategyData.description)
      methods.setValue('duration', editStrategyData.duration)
    }
  }, [methods, isEditing, editStrategyData])

  const selectedOrderValue = methods.watch('order')

  return (
    <Form
      initialValues={formStrategy}
      onSubmit={onSubmitStage}
      methods={methods}
    >
      <Box>
        <Box>
          {loading ? (
            <Skeleton width={'20rem'} height={'2rem'} />
          ) : (
            <FormDropdown
              disabled={isEditing}
              id="description-dropdown"
              validation={getValidation(strategyStageValidation, 'order')}
              name="order"
              placeholder="Description"
              width="20rem"
              options={getNextAvailableOrder()?.map((order) => ({
                label: order.label,
                value: order.value,
              }))}
              value={selectedOrderValue || ''}
            />
          )}
        </Box>
        <Box mt={3}>
          <FormInput
            id="duration-days-input"
            validation={
              selectedOrderValue === CHARGE_OFF_STRATEGY_STAGE.value
                ? undefined
                : getValidation(strategyStageValidation, 'duration')
            }
            placeholder="Duration (days)"
            name="duration"
            type="number"
            width="20rem"
            disabled={selectedOrderValue === CHARGE_OFF_STRATEGY_STAGE.value}
          />
        </Box>
      </Box>
      {isEditing ? (
        <FormModalSubmitButton disabled={loading || false} text="Save" />
      ) : (
        <FormModalSubmitButton disabled={loading || false} />
      )}
    </Form>
  )
}

export default StrategyStageForm
